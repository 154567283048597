<template>
    <div>
        <HeaderPortrate />
        <div class="m-2 pa-2">
            <tabs-comp :tabs="tabs" />
           
            <v-row style="margin-bottom:50px !important;">
                <v-col cols="12">
                    <v-simple-table striped hover style="width:100%;">
                        <thead>
                            <tr>
                                <th class="text-center">الاسرة</th>
                                <th class="text-center">تاريخ الدفعة</th>
                                <th class="text-center">بداية الفترة</th>
                                <th class="text-center">نهاية الفترة</th>
                                <th class="text-center">اجمالي المبلغ</th>
                                <th class="text-center">نسبة المحل</th>
                                <th class="text-center">المبلغ المستلم</th>
                                <th class="text-center">الحالة</th>
                                <th class="text-center" style="width:200px">{{lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in myrequests" :key="index">
                                <td class="text-center">{{item.vendor_name}}</td>
                                <td class="text-center">{{String(item.reqdate).substr(0,10)}}</td>
                                <td class="text-center">{{String(item.sdate).substr(0,10)}}</td>
                                <td class="text-center">{{String(item.edate).substr(0,10)}}</td>
                                <td class="text-center">{{item.total}}</td>
                                <td class="text-center">{{item.percentage}}</td>
                                <td class="text-center" v-html="getTotal(item)"></td>
                                <td class="text-center" v-html="getStatus(item.status)"></td>
                                <td class="text-center">
                                    <v-btn style="width:90px;font-size:0.2em;background:black;box-shadow:none;color:#fff;height:25px !important;" @click="PrintItem(item)">{{lang.print}} &nbsp;	&nbsp;	<i :class="`fas fa-print`"></i></v-btn>
                                    <v-btn style="margin:auto 3px;width:90px;font-size:0.2em;background:green;box-shadow:none;color:#fff;height:25px !important;" v-if="item.status == 1" @click="payItem(item)">سداد &nbsp;	&nbsp;	<i class="fas fa-money-bill"></i></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default({
    components: {HeaderPortrate,Footer,TabsComp},
    data() {
        return {
            myrequests:[
              
            ]
        }
    },
    created() {
      this.getPayment();
    },
    methods: {
      PrintItem(item){
        let pdfFrame = document.body.appendChild(document.createElement('iframe'));
        pdfFrame.style.display = 'none';
        pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
        pdfFrame.src = '../api/print-80m.php?payreq='+item.id;
      },
      payItem(item){
          const post = new FormData();
          post.append('type', 'updoPayment');
          post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
          post.append('data[id]',item.id);
          axios.post(
              this.$store.state.SAMCOTEC.r_path, post
          ).then((response) => {
              const res = response.data;
              this.getPayment();
              this.PrintItem(item);
          });
      },
      getTotal(item){
          let t = 0;
          t = parseFloat(item.total) - parseFloat(item.percentage);
          return this.$RoundNum(t);
      },
      getStatus(status){
          if(status == 1) return "طلب جديد";
          if(status == 2) return "تم السداد";
      },
      requestpayment(){
          const post = new FormData();
          post.append('type', 'requestVpayment');
          post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
          post.append('data[a]',1);
          axios.post(
              this.$store.state.SAMCOTEC.r_path, post
          ).then((response) => {
              const res = response.data;
              // console.log(res);
              this.getPayment();
          });
      },
      getPayment(){
        const post = new FormData();
          post.append('type', 'getVpayment');
          post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
          post.append('data[a]',2);
          axios.post(
              this.$store.state.SAMCOTEC.r_path, post
          ).then((response) => {
              const res = response.data;
              // console.log(res);
              this.myrequests = res.results.data;
          });
      }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }, 
        tabs: function(){
            let t = [
                
                ]
            if(this.$store.state.licenseType.crm){
                t.push({
                        index:6,name:this.lang.projects,href:'/customers',class:'mytab4',status:1
                    })
            }
            t.push({
                index:0,name:this.lang.customers,href:'/clientList',class:'mytab4',status:1
            })
            t.push({
                index:5,name:this.lang.invoices,href:'/invoicelist',class:'mytab4',status:1
            })
            if(this.$store.state.quotation){
              t.push({
                  index:1,name:this.lang.new_quotations,href:'/quotations/n/1',class:'mytab3',status:1
              })
              t.push({
                  index:2,name:this.lang.invoiced_quots,href:'/quotations/i/2',class:'mytab3',status:2
              })
              t.push({
                  index:3,name:this.lang.canceled_quots,href:'/quotations/c/3',class:'mytab3',status:3
              })
            }
            if(this.$store.state.licenseType.families){
                t.push({
                    index:3,name:this.lang.sent_requests,href:'/vendor-sents',class:'mytab3',status:3
                })
                t.push({
                    index:3,name:this.lang.payment_requests,href:'/vendor-prequests',class:'mytab',status:3
                })
            }
            return t;
        }
    }
})
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
</style>